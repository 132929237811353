<template>
  <div class="flex justify-content-between c-search-header">
    <h3>{{ t("search.order", 2) }} ({{ props.orderResult?.totalHits }})</h3>
    <PrimeButton
      :label="t('search.show-all')"
      class="p-button-link"
      @click="$emit('goToPage', Pages.Order, null, false)"
      data-testid="order-show-all"
    ></PrimeButton>
  </div>
  <div
    v-for="order in props.orderResult?.orders ?? []"
    :key="order.id"
    data-testid="order-result-row"
    class="flex align-items-center c-search-description"
  >
    <div class="c-search-image">
      <i class="pi pi-shopping-cart c-search-order-icon"></i>
    </div>

    <div class="c-link" @click="$emit('goToPage', Pages.Order, order.id, false)">
      <span class="c-link-title">{{ order.customer.customerName }}</span>
      <span class="c-link-subtitle">{{ order.orderNumber }}</span>
    </div>

    <div class="c-link-folder" @click="$emit('goToPage', Pages.Order, order.id, true)">
      <i class="pi pi-arrow-up-right"></i>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { OrderSearchResponse } from "../models/order/OrderSearchResponse";
import { Pages } from "../models/search/Pages";

const props = defineProps<{ orderResult: OrderSearchResponse | null }>();
defineEmits<{ (event: "goToPage", page: Pages, id: string | null, openTab: boolean): void }>();

const { t } = useI18n();
</script>
