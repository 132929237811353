<template>
  <div class="flex justify-content-between c-search-header">
    <h3>{{ t("search.goods-reception", 2) }} ({{ props.goodsReceptionResult?.totalHits ?? 0 }})</h3>
    <PrimeButton
      :label="t('search.show-all')"
      class="p-button-link"
      @click="$emit('goToPage', Pages.GoodsReception, null, false)"
      data-testid="goods-reception-show-all"
    ></PrimeButton>
  </div>
  <div
    v-for="goodsReception in props.goodsReceptionResult?.purchaseOrders ?? []"
    :key="goodsReception.id"
    data-testid="goods-reception-result-row"
    class="flex align-items-center c-search-description"
  >
    <div class="c-search-image">
      <i class="pi pi-sign-in c-search-goods-reception-icon"></i>
    </div>

    <div class="c-link" @click="$emit('goToPage', Pages.GoodsReception, goodsReception.id, false)">
      <span class="c-link-title">{{ goodsReception.supplier.name }}</span>
      <span class="c-link-subtitle">{{ goodsReception.purchaseOrderNumber }}</span>
    </div>

    <div class="c-link-folder" @click="$emit('goToPage', Pages.GoodsReception, goodsReception.id, true)">
      <i class="pi pi-arrow-up-right"></i>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { Pages } from "../models/search/Pages";
import { PurchaseOrderSearchResponse } from "../models/purchase/PurchaseOrderSearchResponse";

const props = defineProps<{ goodsReceptionResult: PurchaseOrderSearchResponse | null }>(); //Goods reception search result is the same as purchase order search result
defineEmits<{ (event: "goToPage", page: Pages, id: string | null, openTab: boolean): void }>();

const { t } = useI18n();
</script>
