import { Company } from "@cumulus/event-bus";
import router from "@/router/router";
import { publishSetSelectedLocaleEvent } from "@cumulus/locale";
import { companyMenu } from "./CompanyMenu";
import { clientMenu } from "./ClientMenu";
import { useAuth } from "@cumulus/event-bus";
import { PrimeIcons } from "primevue/api";
import { MenuItem } from "primevue/menuitem";

export function useProfile() {
  const { getUser, logout } = useAuth();

  const getMenu = async (): Promise<Array<MenuItem>> => {
    const user = await getUser();
    const employee = user.getEmployee();
    const activeClient = user.getClient();

    const companies = user.employees.map((employee) => {
      const company = new Company();
      company.id = employee.companyId;
      company.name = employee.companyName;
      return company;
    });

    //Only show is more then one company
    const companyMenuItems = [] as Array<MenuItem>;
    if (companies.length > 1) {
      companyMenuItems.push({
        label: "header.profile-companies",
        icon: PrimeIcons.BUILDING,
        items: companyMenu(user, companies, activeClient?.companyId ?? ""),
      });
    }

    //Only show is more then one client
    const clientMenuItems = [] as Array<MenuItem>;
    if (employee.clients.length > 1) {
      clientMenuItems.push({
        label: "header.profile-clients",
        icon: "pi pi-fw pi-sitemap",
        items: clientMenu(user, employee.clients, activeClient?.id ?? ""),
      });
    }

    return [
      {
        label: "header.profile-user",
        icon: "pi pi-fw pi-user",
        command: () => {
          router.push(`/system-data/employee/edit/${employee.id}`);
        },
      },
      {
        label: "header.profile-language",
        icon: "pi pi-fw pi-flag",
        items: [
          {
            label: "common.countries.language.nb-NO",
            icon: "nb-NO",
            command: () => {
              publishSetSelectedLocaleEvent("nb-NO");
            },
          },
          {
            label: "common.countries.language.en-GB",
            icon: "en-GB",
            command: () => {
              publishSetSelectedLocaleEvent("en-GB");
            },
          },
        ],
      },
      ...companyMenuItems,
      ...clientMenuItems,
      {
        label: "header.profile-logout",
        icon: "pi pi-fw pi-power-off",

        command: () => {
          logout();
        },
      } as MenuItem,
    ] as Array<MenuItem>;
  };

  return {
    getMenu,
  };
}
