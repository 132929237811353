<template>
  <div class="c-layout">
    <div class="c-navbar">
      <Header
        :isMobile="isMobile"
        :showSidebarMini="showSidebarMini"
        :showMobileMenu="showMobileMenu"
        @onToggleSidebarMini="onToggleSidebarMini"
        @onToggleMobileMenu="onToggleMobileMenu"
      />
    </div>

    <div v-if="isMobile" class="c-mobile-toolbar" id="c-mobile-toolbar">
      <MobileToolbar
        @onToggleMobileMenu="onToggleMobileMenu"
        @onToggleMore="toggleMoreOptions"
        @onToggleSearch="onToggleSearch"
      />
    </div>
    <div
      class="c-mobile-toolbar c-more-options-toolbar"
      id="c-more-options-menu"
      v-show="moreOptionsVisible && isMobile"
    ></div>
  </div>
  <Teleport to="body">
    <SidebarMenu
      :isMobile="isMobile"
      :showSidebarMini="showSidebarMini"
      :showMobileMenu="showMobileMenu"
      @onToggleMobileMenu="onToggleMobileMenu"
    />
  </Teleport>
  <Teleport to="body" v-if="isMobile">
    <div
      class="c-sidebar-overlay"
      :class="[{ 'c-sidebar-mobile': isMobile }, { 'c-sidebar-overlay-active': showMobileMenu }]"
      @click="onToggleMobileMenu"
    ></div>
  </Teleport>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import Header from "./components/Header.vue";
import SidebarMenu from "./sidebar/SidebarMenu.vue";
import { useMobile } from "@/views/MobileService";
import { useBodyCss } from "@/views/BodyCssService";
import { useLocalStorage } from "@/views/LocalStorageService";
import MobileToolbar from "./components/MobileToolbar.vue";

const { addSidebarMiniCss, removeSidebarMiniCss, addShowMobileMenuCss, removeShowMobileMenuCss } = useBodyCss();
const { showSidebarMini } = useLocalStorage();

const { isMobile } = useMobile();

const showMobileMenu = ref(false);
const initMobileMenu = () => {
  showMobileMenu.value = false;
  removeShowMobileMenuCss();
};

initMobileMenu();

watch(
  () => isMobile.value,
  (mobile: boolean) => {
    if (mobile) {
      removeSidebarMiniCss();
    } else if (showSidebarMini.value) {
      addSidebarMiniCss();
    }

    removeShowMobileMenuCss();
  }
);

showSidebarMini.value ? addSidebarMiniCss() : removeSidebarMiniCss();

const onToggleMobileMenu = () => {
  showMobileMenu.value = !showMobileMenu.value;
  showMobileMenu.value ? addShowMobileMenuCss() : removeShowMobileMenuCss();
};

const onToggleSidebarMini = () => {
  showSidebarMini.value = !showSidebarMini.value;
  showSidebarMini.value ? addSidebarMiniCss() : removeSidebarMiniCss();
};

const onToggleSearch = () => {
  document.getElementById("searchInput")?.focus();
};

const moreOptionsVisible = ref<boolean>(false);

const toggleMoreOptions = () => {
  moreOptionsVisible.value = !moreOptionsVisible.value;
};
</script>

<style lang="scss" scoped>
.c-layout {
  position: fixed;
  width: 100%;
  box-shadow: var(--navbar-box-shadow);
  z-index: 3;
}

.c-sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  transition-duration: 0.2s;

  &.c-sidebar-mobile.c-sidebar-overlay-active {
    z-index: 3;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    @media (max-width: 687px) {
      height: 90vh;
    }
    @media (min-width: 687px) and (max-width: 992px) {
      height: 92vh;
    }
  }
}

@media (max-width: 992px) {
  :deep(.p-button.p-button-text) {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  :deep(.p-button:focus) {
    box-shadow: none;
  }
}
</style>
