<template>
  <div class="flex justify-content-between c-search-header">
    <h3>{{ t("search.supplier", 2) }} ({{ supplierResult?.totalHits }})</h3>
    <PrimeButton
      :label="t('search.show-all')"
      class="p-button-link"
      @click="$emit('goToPage', Pages.Supplier, null, false)"
      data-testid="supplier-show-all"
    ></PrimeButton>
  </div>
  <div
    v-for="supplier in supplierResult?.suppliers ?? []"
    :key="supplier.id"
    data-testid="supplier-result-row"
    class="flex align-items-center c-search-description"
  >
    <div class="c-search-image">
      <i class="pi pi-car c-search-supplier-icon"></i>
    </div>

    <div class="c-link" @click="$emit('goToPage', Pages.Supplier, supplier.id, false)">
      <span class="c-link-title">{{ supplier.name }}</span>
      <span class="c-link-subtitle">{{ supplier.supplierNumber }}</span>
    </div>

    <div class="c-link-folder" @click="$emit('goToPage', Pages.Supplier, supplier.id, true)">
      <i class="pi pi-arrow-up-right"></i>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { SupplierSearchResponse } from "../models/supplier/SupplierSearchResponse";
import { Pages } from "../models/search/Pages";

defineProps<{ supplierResult: SupplierSearchResponse | null }>();
defineEmits<{ (event: "goToPage", page: Pages, id: string | null, openTab: boolean): void }>();

const { t } = useI18n();
</script>
