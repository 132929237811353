<template>
  <div class="flex justify-content-between c-search-header">
    <h3>{{ t("search.customer", 2) }} ({{ props.customerResult?.totalHits }})</h3>
    <PrimeButton
      :label="t('search.show-all')"
      class="p-button-link"
      @click="$emit('goToPage', Pages.Customer, null, false)"
      data-testid="customer-show-all"
    ></PrimeButton>
  </div>
  <div
    v-for="customer in props.customerResult?.customers ?? []"
    :key="customer.id"
    data-testid="customer-result-row"
    class="flex align-items-center c-search-description"
  >
    <div class="c-search-image">
      <i class="pi pi-user c-search-customer-icon"></i>
    </div>

    <div class="c-link" @click="$emit('goToPage', Pages.Customer, customer.id, false)">
      <span class="c-link-title">{{ customer.name }}</span>
      <span class="c-link-subtitle">{{ customer.customerNumber }}</span>
    </div>

    <div class="c-link-folder" @click="$emit('goToPage', Pages.Customer, customer.id, true)">
      <i class="pi pi-arrow-up-right"></i>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { CustomerSearchResponse } from "../models/search/CustomerSearchResponse";
import { Pages } from "../models/search/Pages";

const props = defineProps<{ customerResult: CustomerSearchResponse | null }>();
defineEmits<{ (event: "goToPage", page: Pages, id: string | null, openTab: boolean): void }>();

const { t } = useI18n();
</script>
