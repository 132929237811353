<template>
  <div
    class="c-header"
    :class="{ 'c-header-full-logo': isMobile && route.path === '/' }"
    data-testid="header-signed-in"
  >
    <div
      class="col-2 md:col-4 flex align-items-center justify-content-start"
      :class="{ 'col-12 justify-content-center': isMobile && route.path === '/' }"
    >
      <router-link to="/" class="c-logo">
        <picture>
          <source srcset="@/assets/images/logo.png" />
          <img src="@/assets/images/logo_smal.png" alt="Multicase Portal" />
        </picture>
      </router-link>
      <PrimeButton
        :icon="showSidebarMini ? 'pi pi-bars' : 'pi pi-align-right'"
        class="p-button-rounded p-button-text c-button-min"
        @click="emit('onToggleSidebarMini')"
        v-show="!isMobile"
      />
      <div class="pl-5" v-if="currentContext">
        <h1>{{ t(`header.title.${currentContext}`) }}</h1>
      </div>
      <div id="c-navbar-title" class="c-hide-in-navbar pl-5" v-show="!isMobile"></div>
    </div>
    <div
      :class="{ 'col-12': isMobile && route.path === '/' }"
      class="col-10 md:col-8 lg:col-4 flex justify-content-center align-items-center"
    >
      <Search :isMobile="isMobile" />
    </div>
    <div v-if="!isMobile" class="col-4 lg:col-4 md:col-4 sm:col-4 flex justify-content-end align-items-center">
      <div id="c-navbar-shortcuts" class="c-hide-in-navbar" v-show="!isMobile"></div>

      <UserProfile v-show="showUserProfile" />
      <Alerts v-show="showUserProfile" />

      <PrimeButton
        :icon="showMobileMenu ? 'pi pi-times' : 'pi pi-bars'"
        class="p-button-rounded p-button-text c-mobile-menu"
        @click="emit('onToggleMobileMenu')"
        v-show="isMobile"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import Search from "@/views/auth/search/components/Search.vue";
import Alerts from "./Alerts.vue";
import UserProfile from "./UserProfile.vue";
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

defineProps<{
  isMobile: boolean;
  showSidebarMini: boolean;
  showMobileMenu: boolean;
}>();

const emit = defineEmits<{
  (e: "onToggleSidebarMini"): void;
  (e: "onToggleMobileMenu"): void;
}>();

const showUserProfile = ref<boolean>(true);
const route = useRoute();
const { t } = useI18n();

const contexts = [
  { path: "/purchase", key: "purchase" },
  { path: "/customer", key: "customer" },
  { path: "/goods-reception", key: "goods-reception" },
];

const showInNavbarOnScroll = () => {
  if (import.meta.env.VITE_APP_STANDALONE === "true") {
    return;
  }
  const shortcutElement = document.getElementById("c-navbar-shortcuts");
  const titleElement = document.getElementById("c-navbar-title");

  if (shortcutElement && titleElement) {
    if (titleElement.children.length === 0 && shortcutElement.children.length === 0) {
      shortcutElement.classList.remove("c-show-in-navbar");
      titleElement.classList.remove("c-show-in-navbar");
      showUserProfile.value = true;
      return;
    }

    const headerOffSet = shortcutElement.offsetTop;
    if (window.pageYOffset > headerOffSet) {
      titleElement.classList.add("c-show-in-navbar");
      shortcutElement.classList.add("c-show-in-navbar");
      showUserProfile.value = false;
    } else {
      shortcutElement.classList.remove("c-show-in-navbar");
      titleElement.classList.remove("c-show-in-navbar");
      showUserProfile.value = true;
    }
  }
};

const currentContext = computed<string>(() => {
  const currentPath = route.path;
  const context = contexts.find((ctx) => currentPath.startsWith(ctx.path));
  return context ? context.key : "";
});

onMounted(() => {
  window.addEventListener("scroll", showInNavbarOnScroll);
});
onUnmounted(() => {
  window.removeEventListener("scroll", showInNavbarOnScroll);
});
</script>

<style lang="scss" scoped>
.c-hide-in-navbar {
  visibility: hidden;
}

.c-show-in-navbar {
  visibility: visible;
}

.c-header {
  display: flex;
  flex-direction: row;
  padding: 0.1rem 0.5rem;

  height: 71px;
  background-color: #fff;
  box-shadow: 2px 3px 5px #8c8c8c26;

  img {
    @media (max-width: 767px) {
      height: 40px;
      width: 55px;
      object-fit: cover;
      object-position: 0% 25%;
      transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
  }
  &.c-header-full-logo {
    height: auto;
    flex-direction: column;
    justify-content: center;
    img {
      width: 172px;
    }
  }
}

.c-use-megamenu .c-header {
  z-index: 997;
}

.c-logo {
  img {
    @media (min-width: 992px) {
      height: 40px;
      width: 172px;
      object-fit: cover;
      object-position: 0% 0%;
      transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
  }
}
.c-sidebar-mini .c-logo {
  img {
    @media (min-width: 992px) {
      height: 40px;
      width: 55px;
      object-fit: cover;
      object-position: 0% 25%;
      transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
  }
}

.c-button-min {
  margin-left: 1.8rem;

  &.p-button:focus {
    box-shadow: none;
  }

  :deep(.pi) {
    font-size: 1.5rem;
  }
}

.c-nav-tools {
  margin: 0.5rem 1rem 0 0rem;
}
</style>

<style lang="scss">
.c-use-megamenu .p-sidebar-mask {
  z-index: 2;
}
</style>
