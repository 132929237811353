<template>
  <div class="c-alerts">
    <PrimeButton
      type="button"
      class="p-button-text c-alert-button"
      @click="toggleAlerts"
      aria-haspopup="true"
      aria-controls="alert-menu"
      badge="8"
      badgeClass="p-badge-warning"
    >
      <template #icon>
        <span class="material-symbols-outlined">notifications</span>
      </template>
    </PrimeButton>

    <TieredMenu id="alert-menu" ref="tieredMenuAlert" :model="alertItems" :popup="true">
      <template #item="{ item }">
        <router-link
          v-if="item.to"
          :to="item.to"
          :class="[item.class, 'p-menuitem-link', { 'p-disabled': item.disabled }]"
          :style="item.style"
          @click="
            toggleAlerts($event);
            navigate($event, item);
          "
          :target="item.target"
          role="alert-menu"
        >
          <span class="p-menuitem-icon" :class="item.icon"></span>
          <span class="p-menuitem-text">{{ t(item.label as string) }}</span>
          <span class="p-ink"></span>
        </router-link>

        <a
          v-if="!item.to"
          :href="item.url || '#'"
          :style="item.style"
          :class="[item.class, 'p-menuitem-link', { 'p-disabled': item.disabled }]"
          @click="
            toggleAlerts($event);
            navigate($event, item);
          "
          :target="item.target"
          role="alert-menu"
        >
          <span class="p-menuitem-icon" :class="item.icon"></span>
          <span class="p-menuitem-text">{{ t(item.label as string) }}</span>
          <span class="p-ink"></span>
        </a>
      </template>
    </TieredMenu>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { MenuItem } from "primevue/menuitem";
import { alertMenu } from "../menus/AlertMenu";
import { useI18n } from "vue-i18n";
import { navigate } from "./Navigate";

const { t } = useI18n();
const alertItems = ref<Array<MenuItem>>([]);

const fetchAlertItems = () => {
  alertItems.value = alertMenu();
};

onMounted(fetchAlertItems);

const tieredMenuAlert = ref();

const toggleAlerts = (event: Event) => {
  tieredMenuAlert.value.toggle(event);
};
</script>

<style lang="scss" scoped>
.c-alert-button.p-button {
  width: 3.5rem;
  height: 3.5rem;
  :deep(.pi) {
    font-size: 1.25rem;
  }
  :deep(.p-badge) {
    margin-left: -1.088rem;
    margin-top: -1.013rem;
  }
}
</style>
