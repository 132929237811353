<template>
  <CustomToast />
  <PrimeVueLocaleHandler />
  <router-view v-slot="{ Component }">
    <component :is="Component" :key="$route.fullPath" />
  </router-view>
</template>

<script setup lang="ts">
import { PrimeVueLocaleHandler } from "@cumulus/locale";
import { onMounted } from "vue";
import { CustomToast } from "@cumulus/toast";
import { navigationShortcut } from "@cumulus/shortcut";
import { useAuth } from "@cumulus/event-bus";
navigationShortcut();

//Only running this code if the app is standalone
if (import.meta.env.VITE_APP_STANDALONE === "true") {
  const { getUser, isAuthenticated } = useAuth();
  onMounted(async () => (document.body.className = (await getUser()).getBodyCssClass(isAuthenticated.value)));
}
</script>
