<template>
  <div class="c-user-profile">
    <div v-if="isMobile">
      <PrimeButton class="p-button-text" @click="toggleUserProfile">
        <i class="pi pi-user"></i>
        <span class="c-mobile-toolbar-text">{{ fullname }}</span>
      </PrimeButton>
    </div>
    <div v-else>
      <PrimeButton
        class="c-user-profile-button p-button-text"
        type="button"
        @click="toggleUserProfile"
        aria-haspopup="true"
        aria-controls="user-profile-menu"
        data-testid="user-profile-menu"
      >
        <span class="c-user-profile-text"
          >{{ fullname }} <span class="c-user-profile-client"> {{ companyName }}</span></span
        >
        <div class="p-button p-button-info p-button-icon-only p-button-rounded">
          <span class="material-symbols-outlined">person</span>
        </div>
      </PrimeButton>
    </div>

    <TieredMenu id="user-profile-menu" ref="refUserProfileMenu" :model="userProfileItems" :popup="true">
      <template #item="{ item }">
        <router-link
          v-if="item.to"
          :to="item.to"
          :class="[item.class, 'p-menuitem-link', { 'p-disabled': item.disabled }]"
          :style="item.style"
          @click="
            navigate($event, item);
            toggleUserProfile($event);
          "
          :target="item.target"
          role="user-profile-menu"
          :data-testid="replaceDotWithUnderscore(item?.label as string | undefined)"
        >
          <span class="p-menuitem-icon" :class="advanceIcon(item.icon)"></span>
          <span class="p-menuitem-text">{{
            item.skiptranslate ? (item.label as string) : t(item.label as string)
          }}</span>
          <span class="p-ink"></span>
        </router-link>

        <a
          v-if="!item.to && !item.items"
          :href="item.url || '#'"
          :style="item.style"
          :class="[item.class, 'p-menuitem-link', { 'p-disabled': item.disabled }]"
          @click="
            navigate($event, item);
            toggleUserProfile($event);
          "
          :target="item.target"
          role="user-profile-menu"
          :data-testid="replaceDotWithUnderscore(item?.label as string | undefined)"
        >
          <span class="p-menuitem-icon" :class="advanceIcon(item.icon)"></span>
          <span class="p-menuitem-text">{{
            item.skiptranslate ? (item.label as string) : t(item.label as string)
          }}</span>
          <span class="p-ink"></span>
        </a>

        <a
          v-if="!item.to && item.items"
          :style="item.style"
          :class="[item.class, 'p-menuitem-link', { 'p-disabled': item.disabled }]"
          role="user-profile-menu"
          :data-testid="replaceDotWithUnderscore(item?.label as string | undefined)"
        >
          <span class="p-menuitem-icon" :class="advanceIcon(item.icon)"></span>
          <span class="p-menuitem-text">{{ t(item.label as string) }}</span>
          <span class="p-ink"></span>
        </a>
      </template>
    </TieredMenu>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { MenuItem } from "primevue/menuitem";
import { useProfile } from "../menus/UserProfileMenu";
import { navigate } from "./Navigate";
import { useAuth } from "@cumulus/event-bus";

const { t, locale } = useI18n();
const toggle = ref(false);
const refUserProfileMenu = ref();

defineProps<{
  isMobile?: boolean;
}>();
const toggleUserProfile = (event: Event) => {
  toggle.value = !toggle.value;
  refUserProfileMenu.value.toggle(event);
};

const replaceDotWithUnderscore = (value: string | undefined): string => {
  if (value === undefined) {
    return "";
  }
  return value.toString().replaceAll(".", "_");
};

const advanceIcon = (icon: string | undefined) => {
  if (icon === undefined) {
    return "";
  }

  switch (icon) {
    case "nb-NO":
    case "en-GB": {
      return locale.value === icon ? "pi pi-fw pi-check" : "pi pi-fw";
    }
  }
  return icon;
};

const { getUser } = useAuth();
const companyName = ref<string>();
const fullname = ref<string>();

onMounted(async () => {
  const user = await getUser();
  companyName.value = user.getCompany().name;
  fullname.value = user.getFullname();
});

const { getMenu } = useProfile();
const userProfileItems = ref<Array<MenuItem>>();
const fetchUserProfileMenu = async () => {
  userProfileItems.value = await getMenu();
};

onMounted(fetchUserProfileMenu);
</script>

<style lang="scss" scoped>
.c-user-profile-button {
  padding: 0;

  &.p-button.p-button-text:enabled,
  &.p-button.p-button-text:active,
  &.p-button.p-button-text:focus {
    box-shadow: none;
  }

  .p-button.p-button-info {
    &:hover,
    &:active {
      background: var(--info-color-dark);
    }
  }

  .pi {
    font-size: 12px;
  }
}
.c-divider {
  display: none;

  @media (min-width: 992px) {
    padding-bottom: 0.6rem;
    display: inline-block;

    &:before {
      content: "|";
    }
  }
}

.c-user-profile-text {
  font-size: 14px;
  color: var(--text-color);
  font-weight: 600;

  .c-user-profile-client {
    font-weight: 100;
  }

  @media (min-width: 992px) {
    display: inline-block;
    padding-right: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 16rem;
  }
}
</style>

<style lang="scss">
#user-profile-menu.p-tieredmenu {
  right: 2px !important;
  left: auto !important;
  .p-menuitem-active > .p-submenu-list {
    left: -100%;
  }
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 14;
}
</style>
