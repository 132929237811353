<template>
  <div>
    <div class="flex justify-content-between c-search-header">
      <h3>
        {{ productType === "product" ? t("search.product", 2) : t("search.inventory", 1) }} ({{
          props.productResult?.totalHits
        }})
      </h3>
      <PrimeButton
        :label="t('search.show-all')"
        class="p-button-link"
        @click="$emit('goToPage', null, false)"
        :data-testid="`${productType}-show-all`"
      ></PrimeButton>
    </div>
    <div
      v-for="product in props.productResult?.products ?? []"
      :key="product.id"
      :data-testid="`${productType}-result-row`"
      class="flex align-items-center c-search-description"
    >
      <div class="c-search-image">
        <img
          v-if="product.productImages.length > 0"
          :src="resizeImage(product.productImages[0].url, 24, 24)"
          class="c-search-image"
        />
        <img v-else src="@/assets/images/no-image.jpg" alt="No image found" class="c-no-search-image" />
      </div>

      <div class="c-link" @click="$emit('goToPage', product.id, false)">
        <span class="c-link-title">{{ product.name }}</span>
        <span class="c-link-subtitle">{{ product.productNumber }}</span>
      </div>

      <div class="c-link-folder" @click="$emit('goToPage', product.id, true)">
        <i class="pi pi-arrow-up-right"></i>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ProductSearchResponse } from "../models/product/ProductSearchResponse";
import { SupplierSearchResponse } from "../models/supplier/SupplierSearchResponse";
import { useImageService } from "../api/image/ImageService";

const props = defineProps<{
  productResult: ProductSearchResponse | null;
  supplierResult: SupplierSearchResponse | null;
  productType: "product" | "inventory";
}>();

defineEmits<{ (event: "goToPage", id: string | null, openTab: boolean): void }>();

const { t } = useI18n();
const { resizeImage } = useImageService();
</script>
