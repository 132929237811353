import { i18n } from "@/locales/i18n";
import { createApp } from "vue";

import PrimeButton from "primevue/button";
import PrimeVue from "primevue/config";
import ProgressSpinner from "primevue/progressspinner";
import Sidebar from "primevue/sidebar";
import TieredMenu from "primevue/tieredmenu";
import InputText from "primevue/inputtext";
import OverlayPanel from "primevue/overlaypanel";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import ProgressBar from "primevue/progressbar";
import BadgeDirective from "primevue/badgedirective";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import PanelMenu from "primevue/panelmenu";
import Chip from "primevue/chip";
import Dropdown from "primevue/dropdown";

// TODO: Build a new version of this package
// import AppInsights from "cumulus-vue-app-insight";

import { vue3Debounce } from "vue-debounce";

export default function configureApp(app: ReturnType<typeof createApp>) {
  app.use(PrimeVue);
  app.use(ToastService);
  app.use(i18n);

  app.component("PrimeButton", PrimeButton);
  app.component("ProgressSpinner", ProgressSpinner);
  app.component("Sidebar", Sidebar);
  app.component("TieredMenu", TieredMenu);
  app.component("Toast", Toast);
  app.component("InputText", InputText);
  app.component("OverlayPanel", OverlayPanel);
  app.component("TabView", TabView);
  app.component("TabPanel", TabPanel);
  app.component("ProgressBar", ProgressBar);
  app.component("PanelMenu", PanelMenu);
  app.component("Chip", Chip);
  app.component("Dropdown", Dropdown);

  // TODO: Build a new version of this package
  // app.use(AppInsights, {
  //   baseName: "Portal",
  //   id: import.meta.env.VITE_APP_INSIGHTS_KEY,
  //   router,
  // });

  app.directive("badge", BadgeDirective);

  app.directive(
    "debounce",
    vue3Debounce({
      listenTo: "input",
    })
  );
}
