import { MenuItem } from "primevue/menuitem";

export const navigate = (event: Event, item: MenuItem): void => {
  if (item.disabled) {
    event.preventDefault();
  }

  //execute command
  if (!item.to && !item.url) {
    event.preventDefault();
  }
};
